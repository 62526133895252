import {
  addMinutes,
  format as formatLocal,
  fromUnixTime,
  isDate,
  isValid,
  parseISO,
  subMinutes,
} from 'date-fns';
import { format as formatWithTimezone, utcToZonedTime } from 'date-fns-tz';

import { DateFormat } from '../../constants/date';
import { Locale } from '../../constants/locale';
import { getDateFormat } from './formats';
import { locales } from './locale';

export function formatDateWithLocalTime(
  date: Date | string | number,
  dateFormat: DateFormat,
  dateLocale: Locale
) {
  if (!dateLocale) {
    throw new Error('You must provide a locale argument');
  }

  const parsedDate = parseValueToDate(date);
  const formatString = getDateFormat(dateFormat, dateLocale);

  return formatLocal(parsedDate, formatString);
}

export function formatDateWithTimezone(
  date: Date | string | number,
  dateFormat: DateFormat,
  options: { timezone: string; locale: Locale }
) {
  const { timezone, locale } = options;

  if (!locale) {
    throw new Error('You must provide a locale argument');
  }

  if (!timezone) {
    throw new Error('You must provide a timezone argument');
  }

  const parsedDate = parseValueToDate(date);
  const formatString = getDateFormat(dateFormat, locale);
  const zonedTime = utcToZonedTime(parsedDate, timezone);

  return formatWithTimezone(zonedTime, formatString, {
    timeZone: timezone,
    locale: locales[locale],
  });
}

function parseValueToDate(value: Date | string | number) {
  if (isDate(value)) return validateDate(value);

  if (typeof value === 'string') {
    const parsedDate = parseISO(value);
    return validateDate(parsedDate);
  }

  if (typeof value === 'number') {
    const parsedDate = fromUnixTime(value);
    return validateDate(parsedDate);
  }

  throw new Error('Date is not in a recognized format');
}

function validateDate(date: Date | string | number | undefined) {
  if (isDate(date) && isValid(date)) return date as Date;

  throw new Error('Invalid date provided');
}

export function getDateWithRoundedMinutes(
  initialStartDateTime: Date,
  minuteInterval = 5,
  opts?: { minutesAhead?: number }
) {
  // add minutes ahead
  const dateTimeWithAddedMins = addMinutes(initialStartDateTime, opts?.minutesAhead || 0);
  // round down to closest 5 minutes
  const remainder = dateTimeWithAddedMins.getMinutes() % minuteInterval;
  return subMinutes(dateTimeWithAddedMins, remainder);
}
