import { DateFormat } from '../../constants/date';
import { Locale, LocaleFormatEntry } from '../../constants/locale';

const localeDateFormat: Record<DateFormat, LocaleFormatEntry> = {
  [DateFormat.ISO_8601]: {
    default: 'yyyy-MM-dd', // 2021-08-01
  },
  [DateFormat.SHORT_DATE]: {
    default: 'MM/dd/yyyy', // 08/01/2021
    [Locale.enCA]: 'yyyy-MM-dd', // 2021-08-01
    [Locale.enGB]: 'dd/MM/yyyy', // 01/08/2021
  },
  [DateFormat.SHORT_DATE_YY]: {
    default: 'MM/dd/yy', // 08/01/21
    [Locale.enCA]: 'yy-MM-dd', // 21-08-01
    [Locale.enGB]: 'dd/MM/yy', // 01/08/21
  },
  [DateFormat.SHORT_DATE_YY_WITH_TIME]: {
    default: 'MM/dd/yy h:mm a', // 08/01/21 2:00 PM
    [Locale.enCA]: 'yy-MM-dd h:mm a', // 21-08-01 2:00 PM
    [Locale.enGB]: 'dd/MM/yy h:mm a', // 01/08/21 2:00 PM
  },
  [DateFormat.SHORT_DATE_WITH_TIME]: {
    default: `M/d/yyyy 'at' H:mm z`, // 8/1/2021 at 14:00 CST
    [Locale.enCA]: `yyyy-MM-dd 'at' H:mm z`, // 2021-08-01 at 14:00 CST
    [Locale.enGB]: `dd/MM/yyyy 'at' H:mm z`, // 01/08/2021 at 14:00 CST
  },
  [DateFormat.SHORT_DATE_WITH_LOCAL_TIME]: {
    default: `M/d/yyyy 'at' h:mm a`, // 8/1/2021 at 2:00 pm
  },
  [DateFormat.SHORT_MONTH_WITH_LOCAL_TIME]: {
    default: 'MMM d, yyy, h:mm aaa', // Aug 1, 2021, 11:00 am
    [Locale.enGB]: 'd MMM, yyy, h:mm aaa', // 1 Aug, 2021, 11:00 am
  },
  [DateFormat.SHORT_MONTH]: {
    default: 'MMM d, yyyy', // Aug 1, 2021
    [Locale.enGB]: 'd MMM yyyy', // 1 Aug 2021
  },
  [DateFormat.LONG_MONTH_WITH_ORDINAL_DAY]: {
    default: 'MMMM do', // August 28th
  },
  [DateFormat.SHORT_MONTH_WITH_TIME]: {
    default: `MMM d, yyyy 'at' H:mm z`, // Aug 1, 2021 at 14:00 CST
    [Locale.enGB]: `d MMM yyyy 'at' H:mm z`, // 1 Aug 2021 at 14:00 CST
  },
  [DateFormat.SHORT_MONTH_WITH_TIMEZONE_AT_LOCAL_TIME]: {
    default: `MMM d, yyyy 'at' h:mm a z`, // Aug 1, 2021 at 10:00 PM CST
    [Locale.enGB]: `d MMM yyyy 'at' h:mm a z`, // 1 Aug 2021 at 10:00 PM CST
  },
  [DateFormat.LONG_MONTH]: {
    default: 'MMMM dd, yyyy', // August 01, 2021
    [Locale.enGB]: 'dd MMMM yyyy', // 01 August 2021
  },
  [DateFormat.TIME_WITH_TIMEZONE]: {
    default: 'h:mm a zzz', // 11:00 AM CST
  },
  [DateFormat.TIME_24_HR]: {
    default: 'HH:mm',
  },
  [DateFormat.TIME_RELATIVE]: {
    default: `h:mm a 'local time'`, // 11:00 PM local time
  },
  [DateFormat.LONG_DATE]: {
    default: 'EEE, MMM d, yyyy', // Thu, Jan 28, 2021
    [Locale.enGB]: 'EEE, d MMM, yyyy', // Thu, 28 Jan, 2021
  },
  [DateFormat.LONG_DATE_RELATIVE]: {
    default: `EEE, MMM d, yyyy h:mm a 'local time'`, // Thu, Jan 28, 2021 11:00 PM local time
    [Locale.enGB]: `EEE, d MMM yyyy h:mm a 'local time'`, // Thu, 28 Jan 2021 11:00 local time
  },
  [DateFormat.LONG_DATE_TIMEZONE]: {
    default: 'EEE, MMM d, yyyy h:mm a zzz', // Thu, Jan 28, 2021 11:00 PM CST
    [Locale.enGB]: 'EEE, d MMM yyyy h:mm a zzz', // Thu, 28 Jan 2021 11:00 PM GMT-6
  },
  [DateFormat.WEEKDAY_MONTH_DAY]: {
    default: 'EEEE, MMMM d', // Friday, May 26
  },
  [DateFormat.SHORT_TIMEZONE]: {
    default: `zzz ('UTC' XXX)`,
  },
  [DateFormat.LONG_TIMEZONE]: {
    default: `zzzz ('UTC' XXX)`,
  },
};

export function getDateFormat(dateFormat: DateFormat, dateLocale: Locale) {
  const format = localeDateFormat[dateFormat];

  // If there is not a locale override for the given format, use the format default
  return format[dateLocale] || format.default;
}
