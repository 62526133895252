import {
  enCA,
  enGB,
  enUS,
  enAU,
  enNZ,
  es,
  fr,
  it,
  nl,
  pt,
  sv,
  de,
  nlBE,
  pl,
  nb,
  da,
  frCA,
  frCH,
  ptBR,
} from 'date-fns/locale';
import { Locale } from '../../constants/locale';

export const locales = {
  [Locale.enCA]: enCA,
  [Locale.enGB]: enGB,
  [Locale.enUS]: enUS,
  [Locale.enAU]: enAU,
  [Locale.enNZ]: enNZ,
  [Locale.enFR]: enUS,
  [Locale.enBE]: enUS,
  [Locale.enBR]: enUS,
  [Locale.enCH]: enUS,
  [Locale.enDE]: enUS,
  [Locale.enDK]: enUS,
  [Locale.enES]: enUS,
  [Locale.enIT]: enUS,
  [Locale.enMX]: enUS,
  [Locale.enNL]: enUS,
  [Locale.enNO]: enUS,
  [Locale.enPL]: enUS,
  [Locale.enPT]: enUS,
  [Locale.enSE]: enUS,
  [Locale.frFR]: fr,
  [Locale.frBE]: fr,
  [Locale.frCA]: frCA,
  [Locale.frCH]: frCH,
  [Locale.enIE]: enGB,
  [Locale.itIT]: it,
  [Locale.nlNL]: nl,
  [Locale.nlBE]: nlBE,
  [Locale.esES]: es,
  [Locale.ptPT]: pt,
  [Locale.ptBR]: ptBR,
  [Locale.svSE]: sv,
  [Locale.deDE]: de,
  [Locale.plPL]: pl,
  [Locale.noNO]: nb,
  [Locale.daDK]: da,
  [Locale.esMX]: es,
  [Locale.deCH]: de,
};
